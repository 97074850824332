.block-5 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: 6%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #ebf7fa;
}

.pointer {
  cursor: pointer;
}

.try-it-block {
  position: static;
  z-index: 1;
  width: 100%;
  height: 75px;
  margin-top: 4%;
  background-color: transparent;
}

.heading-4-copy {
  position: relative;
  margin-left: 0%;
  font-family: Futurastd, sans-serif;
  color: #000;
  font-size: 3.7vh;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.button-block {
  position: static;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 2%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  flex-wrap: wrap;
}

.card-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 28vh;
  height: 48vh;
  margin-right: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  -o-object-fit: fill;
  object-fit: fill;
}

.link-block {
  margin-top: 4px;
}

img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.div-block-17 {
  width: 100%;
  height: 77%;
  -o-object-fit: fill;
  object-fit: fill;
  flex-direction: column;
  display: flex;
  justify-content: center;
}

.button-copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 0vh;
  margin-left: 0%;
  padding: 14px 45px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #000;
  background-color: #ebf7fa;
  font-family: Futurastd, sans-serif;
  color: #000;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.6px;
}
.button-copy:hover {
  text-decoration: underline;
}

.text-block-4 {
  position: static;
  margin-right: 5px;
  margin-left: 10.5%;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  font-family: 'Graphik cy web', sans-serif;
  color: rgba(0, 0, 0, 0.61);
  font-size: 13px;
}

.image-26 {
  position: absolute;
  height: 14vh;
  margin-top: 4vh;
  padding-left: 2vh;
}

.div-block-20 {
  background: #fff;
}

.image-16 {
  height: 8px;
}
.slider-3 {
  height: 48vh !important;
  margin-top: 65px;
  background-color: #ebf7fa;
}

.wslider-arrow-left,
.wslider-arrow-right {
  position: absolute;
  width: 80px;
  cursor: pointer;
  overflow: hidden;
  color: white;
  font-size: 40px;
  bottom: 0;
  top: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
}

@media (max-width: 400px) {
  .slick-slide {
    justify-content: unset;
  }
}

.wslider-arrow-left {
  left: -80px;
}

.wslider-arrow-right {
  right: -80px;
}

.mask-2 {
  left: 0px;
  right: 0px;
  margin: 0 80px !important;
}

.upl-title {
  display: block;
  overflow: hidden;
  margin: 3vh 0% 0px;
  clear: none;
  font-family: Futura, sans-serif;
  font-size: 18px;
  text-align: center;
  white-space: normal;
  -o-object-fit: fill;
  object-fit: fill;
  height: 30px;
}

.uploaded-image {
  width: 30vh;
  height: 30vh;
  border: 1px dashed #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.ref-img {
  object-fit: contain;
  max-height: 100%;
  transition: all 0.5s ease;
}

.uploaded-image:hover > .ref-img {
  opacity: 0;
}

.error-text {
  text-align: center;
  width: 100%;
}

.hover-img {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  transition: all 0.5s ease;
}

.uploaded-image:hover > .hover-img {
  opacity: 1;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 13%;
}

.list > .link-block-2 {
  margin-top: 35px;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  align-self: center;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #2f394a;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

@media screen and (max-width: 479px) {
  .upl-title {
    font-size: 13px;
  }

  .image-26 {
    height: 9vh;
    margin-top: 2vh;
    padding-left: 1vh;
  }
}
